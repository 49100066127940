
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import ImagesUploader from "@/components/reusable/ImagesUploader.vue";
import variables from "@/router/api";
import $ from "jquery";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "third-part-form-component",
  components: { Field, ErrorMessage, ImagesUploader },
  props: {
    thirdParties: Array,
  },
  data() {
    return {
      isAtFault: { field: false, class: "col-xl-12" },
      displayApprehensionField: false,
      displayBodilyInjury: false,
      displayDeath: false,
      displayThirdPartyVehicle: false,
      displayThirdPartyProperty: false,
      displayThirdPartyBodily: false,
      items: [
        {
          fullName: "",
          phoneNumber: "",
          address: "",
          wereYouAtFault: "",
          apprehendThirdParty: "",
          driverLicense: "",
          thirdPartyIncidentType: "",
          vehicleDamageAmount: "",
          vehicleDamageImages: "",
          propertyDamageImages: "",
          propertyDamageReceipt: "",
          propertyDamageAmount: "",
          propertyDamageAddress: "",
          propertyDamageDescription: "",
          propertyDamageFullName: "",
          propertyDamagePhoneNumber: "",
          bodilyInjurySelection: "",
          bodilyInjuryMedicalReport: "",
          bodilyInjuryImages: "",
          bodilyInjuryMedicalBill: "",
          bodilyInjuryAddress: "",
          bodilyInjuryPhoneNumber: "",
          bodilyInjuryFullName: "",
          deathFullName: "",
          deathPhoneNumber: "",
          deathAddress: "",
          deathPhotographEvidence: "",
          deathCertificate: "",
          deathLossClaim: "",
          deathMOI: "",
          insuranceCertificate: "",
          thirdPartyIncidentVehicle: false,
          thirdPartyIncidentProperty: false,
          thirdPartyIncidentBodily: false,
          displayBodilyInjury: false,
          displayDeath: false,
        },
      ],
    };
  },
  setup() {
    const { isEmptyArray } = ResusableFunctions();

    return {
      isEmptyArray,
    };
  },
  created() {
    if (
      this.thirdParties !== undefined &&
      !this.isEmptyArray(this.thirdParties)
    ) {
      this.items = [...this.thirdParties] as any;
    }
  },
  methods: {
    addCommaToNumber(event) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = newValue));

      // return variables.addCommaToNumber(amount);
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    addDynamicField() {
      this.items.push({
        fullName: "",
        phoneNumber: "",
        address: "",
        wereYouAtFault: "",
        apprehendThirdParty: "",
        driverLicense: "",
        thirdPartyIncidentType: "",
        vehicleDamageAmount: "",
        vehicleDamageImages: "",
        propertyDamageImages: "",
        propertyDamageReceipt: "",
        propertyDamageAmount: "",
        propertyDamageAddress: "",
        propertyDamageDescription: "",
        propertyDamageFullName: "",
        propertyDamagePhoneNumber: "",
        bodilyInjurySelection: "",
        bodilyInjuryMedicalReport: "",
        bodilyInjuryImages: "",
        bodilyInjuryMedicalBill: "",
        bodilyInjuryAddress: "",
        bodilyInjuryPhoneNumber: "",
        bodilyInjuryFullName: "",
        deathFullName: "",
        deathPhoneNumber: "",
        deathAddress: "",
        deathPhotographEvidence: "",
        deathCertificate: "",
        deathLossClaim: "",
        deathMOI: "",
        insuranceCertificate: "",
        thirdPartyIncidentVehicle: false,
        thirdPartyIncidentProperty: false,
        thirdPartyIncidentBodily: false,
        displayBodilyInjury: false,
        displayDeath: false,
      });
    },
    removeDynamicField(counter) {
      $(".remove-form-" + counter).remove();
    },
    getSelectionOption(event, field) {
      this[field] =
        event.target.options[event.target.selectedIndex].value == "Yes"
          ? true
          : false;

      if (this[field] && field === "displayApprehensionField") {
        this.isAtFault.class = "col-xl-8";
        this.displayApprehensionField = true;
      } else {
        this.isAtFault.class = "col-xl-12";
        this.displayApprehensionField = true;
      }
    },
    bodilySelection(type, counter) {
      const item = this.items[counter];
      if (type === "bodilyInjury") {
        item.displayBodilyInjury = !item.displayBodilyInjury;
        if (item.displayBodilyInjury) {
          item.displayDeath = false;
        }
      } else if (type === "death") {
        item.displayDeath = !item.displayDeath;
        if (item.displayDeath) {
          item.displayBodilyInjury = false;
        }
      }
    },

    // selectedFile(event, index) {
    //   this.items[index].imageOfReceipt = event.target.files[0];
    // },
  },
});
